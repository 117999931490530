<template>
  <div class="StoryFull">
    <story-admin-bar :story="story" />
    <div class="container">
      <article
        v-loading="fetchingStory"
        class="StoryFull__wrapper"
        itemtype="http://schema.org/NewsArticle"
        itemscope
      >
        <meta :content="url" itemprop="mainEntityOfPage">

        <div
          itemprop="publisher"
          itemscope=""
          itemtype="https://schema.org/Organization"
        >
          <meta itemprop="name" content="Hype.News">

          <div
            itemprop="logo"
            itemscope=""
            itemtype="https://schema.org/ImageObject"
          >
            <meta :content="$asset('/assets/images/logo_234x60.png')" itemprop="url">
            <meta itemprop="width" content="234">
            <meta itemprop="height" content="60">
          </div>

          <link itemprop="sameAs" href="https://hype.news">
        </div>

        <section class="text-block">
          <div v-if="storyCroppedCover" class="columns is-gapless is-marginless">
            <div class="column is-3 is-flex">
              <newsroom-card
                v-if="newsroom.id"
                :truncate-description="150"
                :newsroom="newsroom"
                class="is-hidden-mobile"
                author-schema
              />
            </div>

            <div class="column is-9 is-position-relative">
              <div
                v-if="storyCroppedCover"
                :style="{backgroundImage: `url(${storyCroppedCover})`}"
                itemprop="image"
                itemscope
                itemtype="http://schema.org/ImageObject"
                class="StoryFull__cover responsive-embed widescreen"
              >
                <meta :content="storyCroppedCover" itemprop="url">
                <meta itemprop="width" content="900">
                <meta itemprop="height" content="506">
              </div>
              <div class="StoryFull__cover-caption m-b-m has-text-grey">
                {{ story.cover_caption }}
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="column is-9 StoryFull__content">
              <div class="StoryFull__content-inner">
                <div class="StoryFull__meta is-hidden-tablet m-b-l">
                  <div v-if="story.type" class="m-b-m">
                    <div class="StoryFull__type" title="Story Type">
                      {{ $t('components.story_types.' + story.type) }}
                    </div>
                  </div>

                  <div class="columns is-vcentered is-marginless is-gapless">
                    <div class="column">
                      <h4 class="m-b-none">
                        {{ $t('pages.story.categories') }}
                      </h4>
                      <story-categories-list :categories="topCategories" />
                    </div>

                    <div class="column is-narrow">
                      <time
                        :datetime="story.published_at"
                        title="Published On"
                        class="StoryFull__publish-date"
                      >
                        {{ datetime(story.published_at, 'DD MMMM YYYY - HH:mm') }}
                      </time>
                    </div>
                  </div>

                  <v-separator />
                </div>

                <div class="StoryFull__headlines-wrap">
                  <h1 itemprop="headline" class="StoryFull__headline">
                    {{ story.headline }}
                  </h1>

                  <p
                    v-if="story.subheadline"
                    itemprop="alternativeHeadline"
                    class="StoryFull__subheadline"
                    v-html="story.subheadline"
                  />
                </div>

                <div
                  itemprop="articleBody"
                  class="StoryFull__copy-text content m-b-xl fr-view"
                  v-html="story.copytext"
                />

                <div class="StoryFull__boilerplate" v-html="story.boilerplate" />
                <v-separator />
                <div class="StoryFull__contact" v-html="story.contact" />

                <story-tags-list
                  v-if="tags.length"
                  :tags="tags"
                  :class="{'m-b-m ': contacts.length}"
                  tag-class="column is-narrow-mobile"
                  class="is-hidden-tablet m-t-m has-text-primary columns is-mobile is-multiline"
                />

                <v-separator
                  v-if="contacts.length"
                  fat
                  darker
                />

                <div v-if="contacts.length" class="StoryFull__contacts">
                  <div class="title-wrap">
                    <h4 class="title is-4 has-text-weight-semibold">
                      {{ $t('pages.story.contacts') }} /
                    </h4>
                    <div class="subtitle is-5">
                      {{ $t('pages.story.for_more_info') }}
                    </div>
                  </div>

                  <div class="columns">
                    <div
                      v-for="contact in contacts"
                      :key="contact.id"
                      class="column is-4 is-flex"
                    >
                      <contact-card :contact="contact" />
                    </div>
                  </div>
                </div>

                <v-separator />

                <div v-if="hasAttachments" class="StoryFull__attachments attachments">
                  <h4>{{ $t('pages.story.attachments') }}</h4>
                  <Attachments :files="story.files.data" />
                </div>
              </div>
            </div>

            <div class="column is-3 is-flex">
              <div class="StoryFull__sidebar">
                <div
                  v-if="!fetchingStory"
                  class="sidebar"
                >
                  <div class="sidebar__inner">
                    <newsroom-card
                      v-if="newsroom.id"
                      :class="{'is-hidden-tablet': storyCroppedCover}"
                      :newsroom="newsroom"
                      class="m-b-m"
                      author-schema
                    />
                    <div class="is-hidden-mobile">
                      <div
                        v-if="story.type"
                        class="StoryFull__type"
                        title="Story Type"
                      >
                        {{ $t('components.story_types.' + story.type) }}
                      </div>

                      <time
                        :datetime="story.published_at"
                        title="Published On"
                        itemprop="datePublished"
                        class="StoryFull__publish-date"
                      >
                        {{ datetime(story.published_at, 'DD MMMM YYYY - HH:mm') }}
                      </time>

                      <meta :content="story.updated_at" itemprop="dateModified">
                      <v-separator fat darker />
                      <h4 class="m-b-none">
                        {{ $t('pages.story.categories') }}
                      </h4>
                      <v-separator />

                      <story-categories-list
                        v-if="topCategories.length"
                        :categories="topCategories"
                      />

                      <div v-if="tags.length">
                        <h4 class="m-b-none">
                          {{ $t('pages.story.tags') }}
                        </h4>
                        <v-separator />
                        <story-tags-list
                          :tags="tags"
                          class="has-text-primary m-b-m is-size-5"
                        />
                      </div>

                      <v-separator v-if="topCategories.length || tags.length" />
                    </div>

                    <ShareOn
                      :url="url"
                      :title="story.title"
                      :description="story.boilerplate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section v-if="hasRelatedStories" class="related-stories">
          <h4>{{ $t('pages.story.related_stories') }}</h4>
          <v-separator />

          <div class="columns is-multiline">
            <div
              v-for="relatedStory in relatedStories"
              :key="relatedStory.id"
              class="column is-6-tablet is-3-desktop is-flex-tablet is-column"
            >
              <story :story="relatedStory" :show-less-info="false" />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<script>
import ContactCard from '@/components/core/ContactCard'
import Story from '@/components/storySearch/StoryCard.vue'
import { changePageTitle, changePageDescription, changeOpenGraphTag, load, persist } from '@/shared/utils'
import StoryCategoriesList from '@hypefactors/shared/js/components/story/StoryCategoriesList'
import StoryTagsList from '@hypefactors/shared/js/components/story/StoryTagsList'
import { mapMutations } from 'vuex'
import ShareOn from '@/components/ShareOn'
import StoryAdminBar from '../components/newsroomEdit/StoryAdminBar'
import { StoryApiService } from '@hypefactors/shared/js/services/api/StoryApiService'
import DatetimeMixin from '@hypefactors/shared/js/mixins/DatetimeMixin'

export default {
  components: {
    StoryTagsList,
    StoryCategoriesList,
    StoryAdminBar,
    Story,
    ContactCard,
    ShareOn
  },

  mixins: [DatetimeMixin],

  data () {
    return {
      story: {
        cover: {},
        categories: {
          data: []
        },
        tags: {
          data: []
        },
        contacts: {
          data: []
        }
      },
      relatedStories: [],

      newsroom: {},
      fetchingStory: true,
      cancelToken: null
    }
  },

  computed: {
    /**
     * Determines if the story has attachments.
     *
     * @return Boolean
     */
    hasAttachments () {
      let attachments = this.story.files

      return attachments && attachments.data.length
    },

    /**
     * Determines if the story has related stories.
     *
     * @return Boolean
     */
    hasRelatedStories () {
      return this.relatedStories.length > 0
    },

    /**
     * Determines if the current route is the story route.
     *
     * @return Boolean
     */
    isStoryRoute () {
      return (this.$route.name || '').startsWith('story')
    },

    /**
     * Returns the current url.
     *
     * @return String
     */
    url () {
      return this.$getUrl(this.$route.fullPath, 'hypenews', null)
    },

    tags () {
      return this.story.tags.data || []
    },

    topCategories () {
      return this.story.categories.data.filter(cat => cat.depth === 2)
    },

    storyCroppedCover () {
      return this.largeImage || this.croppedImagePath
    },

    contacts () {
      return this.story.contacts.data || []
    },

    largeImage () {
      return this.$safeGet(this.story, 'cover.scaled.large.path', null)
    },

    croppedImagePath () {
      return this.$safeGet(this.story, 'cover.cropped', null)
    }
  },

  watch: {
    '$route': function () {
      if (this.isStoryRoute) {
        this.fetchStoryData(this.$route.params.storySlug)
      }
    }
  },

  mounted () {
    this.fetchStoryData(this.$route.params.storySlug)
  },

  beforeDestroy () {
    this.cancelToken && this.cancelToken.cancel()
  },

  methods: {
    ...mapMutations({
      setStory: 'SET_STORY'
    }),
    async fetchStoryData (slug) {
      this.startLoad()

      this.cancelToken && this.cancelToken.cancel()
      this.cancelToken = this.$api.cancelToken()

      this.fetchingStory = true
      try {
        await Promise.all([
          this.fetchStory(slug),
          this.fetchRelatedStories(slug)
        ])
        const legalCookie = load('LEGAL_WARNING', false)

        if (this.newsroom.legal_warning && !legalCookie) {
          try {
            await this.$confirm(this.$t('components.full_story.legal_warning'))
            persist('LEGAL_WARNING', true)
          } catch (e) {
            this.$router.push({ name: 'newsroom.stories', params: { newsroomSlug: this.newsroom.slug } })
          }
        }

        this.stopLoad()
      } catch (err) {
        if (this.$api.isCancelToken(err)) return
        this.$router.push(this.$i18nRoute({ name: 'home' }))
        this.stopLoadWithFail()
      } finally {
        this.fetchingStory = false
      }
    },

    fetchStory (slug) {
      return StoryApiService.fetchStory(slug, {
        params: {
          include: ['newsroom.country', 'files', 'categories', 'tags', 'contacts']
        },
        cancelToken: this.cancelToken.token
      })
        .then((story) => {
          this.story = story

          this.setStory(story)

          this.newsroom = story.newsroom.data

          this.changePageMetaTags(story)
        })
        .catch((error) => {
          if (this.$api.isCancelToken(error)) throw error
          this.$displayRequestError(error, this.$t('errors.error'), 5000, [404]) // skip reporting 404's
          throw error
        })
    },

    async fetchRelatedStories (slug) {
      this.relatedStories = await StoryApiService.fetchRelatedStories(slug, {
        params: {
          limit: 4,
          include: 'newsroom.country'
        },
        cancelToken: this.cancelToken.token
      })
    },

    changePageMetaTags (story) {
      changePageTitle(story.headline)
      changePageDescription(story.excerpt)
      changeOpenGraphTag('title', story.headline)
      changeOpenGraphTag('type', 'article')
      changeOpenGraphTag('url', this.url)
      if (this.$safeGet(story, 'cover.cropped', false)) {
        changeOpenGraphTag('image', story.cover.cropped)
      }
      changeOpenGraphTag('description', story.copytext)
    }
  }
}
</script>
<style src="froala-editor/css/froala_style.css"></style>
