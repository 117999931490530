import { render, staticRenderFns } from "./StoryFull.vue?vue&type=template&id=50e75f6b&"
import script from "./StoryFull.vue?vue&type=script&lang=js&"
export * from "./StoryFull.vue?vue&type=script&lang=js&"
import style0 from "froala-editor/css/froala_style.css?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports