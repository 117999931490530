<template>
  <div
    v-if="!!ownsStory"
    class="StoryAdminBar has-background-white is-full-width"
  >
    <div class="container no-margin">
      <div class="is-flex p-v-m is-aligned-end">
        <a
          :href="editUrl"
          class="is-uppercase p-r-m p-l-m"
          data-testid="StoryEditLink"
          target="hf_story_edit"
        >
          {{ $t('forms.edit') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * @module StoryAdminBar
 */
export default {
  name: 'StoryAdminBar',

  props: {
    /**
     * The currently opened story
     */
    story: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('personalNewsrooms', ['newsroomsFlat']),

    /**
     * Returns the story's newsroom from the Users newsrooms
     * @returns {object} - Newsroom
     */
    storyNewsroom () {
      return this.newsroomsFlat.find(newsroom => newsroom.slug === this.story.newsroom_slug)
    },

    /**
     * Checks if the user is the owner of the current viewed story
     * @returns {boolean}
     */
    ownsStory () {
      return !!this.storyNewsroom
    },

    editUrl () {
      if (!this.ownsStory) return

      return this.$getUrl(`stories/story/${this.story.id}/edit?brand=${this.storyNewsroom.brandId}`, 'hypefactors', false)
    }
  }
}
</script>
